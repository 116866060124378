<template>
    <div class="PickupSupplier">
        <el-dialog title="查询供应商" :visible.sync="dialogFormVisible" width="60%" top="60px" :modal="false">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form @submit.native.prevent :inline="true" v-model="form" size="small">
                    <el-form-item label="">
                        <el-button type="primary" @click="searchHandleQuery">查询</el-button>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input placeholder="供应商名称" v-model="form.search" />
                    </el-form-item>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    ref="pickupSupplierTable"
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column width="40" type="selection" />
                    <el-table-column label="序号" type="index" align="center" fixed="left" />
                    <el-table-column prop="name" label="名称" width="180" />
                    <el-table-column prop="companyCode" label="编码" width="180" />
                    <el-table-column prop="linkman" label="联系人" width="120" />
                    <el-table-column prop="mobile" label="手机号" width="110" />
                    <el-table-column prop="address" label="地址" width="120" />
                    <el-table-column prop="creator" label="创建人" width="120" />
                    <el-table-column prop="createTime" label="创建时间" width="140" />
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="ok">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../js/Util';

export default {
    name: 'PickupSupplier',
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/goods/supplier/page',
            },
            loading: false,
            dialogFormVisible: false,
            meta: {
                brands: [],
                categories: [],
            },
        };
    },
    mounted() {
        this.handleQuery();
    },

    watch: {
        dialogFormVisible() {
            if (this.dialogFormVisible) {
                this.form.search = '';
                this.form.page = 1;
                this.form.limit = Util.Limit;
                this.page.total = 0;
                this.page.pageSizes = Util.PageSizes;
                this.page.PageStyle = Util.PageStyle;
                this.handleQuery();
            }
        },
    },
    methods: {
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTableFlag(_this, _this.url.page, _params, false);
        },

        //公共接口
        ok() {
            this.$emit('ok', this.$refs['pickupSupplierTable'].selection);
            this.dialogFormVisible = false;
        },
        show() {
            this.dialogFormVisible = true;
        },
    },
};
</script>

<style scoped>
.PickupSupplier .el-form-item {
    margin-bottom: 0;
}
</style>
