<template>
    <ef-dialog :visible.sync="showDialog" title="对已勾选商品进行以下操作">
        <el-row style="margin-left: 10px">
            <el-col :span="6" v-if="hasPrivilege('menu.goods.goods.edit')">
                <el-button type="success" size="small" @click="batchUp(0)">批量上架</el-button>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.goods.goods.edit')">
                <el-button type="success" size="small" @click="batchUp(1)">批量下架</el-button>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.stock.loss.create')">
                <el-button type="success" size="small" @click="jumpStockLossCreate">批量报损</el-button>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.stock.surplus.create')">
                <el-button type="success" size="small" @click="jumpStockSurplusCreate">批量报溢</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-left: 10px">
            <el-col :span="6" v-if="hasPrivilege('menu.stock.receive.create')">
                <el-button type="success" size="small" @click="jumpStockReceiveCreate">批量内购</el-button>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.purchase.order.create')">
                <el-popover
                    placement="top"
                    width="300"
                    title="请选择从哪家配送中心采购"
                    v-model="batchDialog.showBatchP"
                >
                    <el-row>
                        <el-select v-model="batchDialog.repoCode" ref="rePoDeptSelect" filterable>
                            <el-option
                                v-for="dept in batchDialog.repoDepts"
                                :label="dept.name"
                                :value="dept.code"
                                :key="dept.code"
                            />
                        </el-select>
                    </el-row>
                    <el-row style="float: right; margin-top: 10px">
                        <el-button size="small" @click="batchDialog.showBatchP = false">取消</el-button>
                        <el-button type="primary" size="small" @click="jumpPurchaseOrderCreate">确定 </el-button>
                    </el-row>
                    <el-button slot="reference" type="success" size="small">批量采购</el-button>
                </el-popover>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.stock.allocate.create')">
                <el-popover
                    placement="top"
                    width="420"
                    :title="batchDialog.deptNameShow"
                    @show="showBatchAllocate"
                    v-model="batchDialog.showBatchD"
                >
                    <el-row>
                        <el-col :span="10">
                            <el-switch
                                v-model="batchDialog.switchType"
                                @change="changeSwitchType"
                                active-text="内部调拨"
                                inactive-text="自建调拨"
                            />
                        </el-col>
                        <el-col :span="14">
                            <span v-show="batchDialog.switchType"> 调入机构：{{ batchDialog.deptName }} </span>
                            <el-switch
                                v-show="!batchDialog.switchType"
                                v-model="batchDialog.switchDept"
                                active-text="当前调出机构"
                                inactive-text="当前调入机构"
                            />
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col v-show="batchDialog.switchType">
                            <span style="padding-right: 10px">调出机构</span>
                            <el-select v-model="batchDialog.otherDeptCode" filterable>
                                <el-option
                                    v-for="item in batchDialog.otherDepts"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                />
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row style="float: right; margin-top: 10px">
                        <el-button size="small" @click="batchDialog.showBatchD = false">取消</el-button>
                        <el-button type="primary" size="small" @click="jumpAllocateCreate">确定</el-button>
                    </el-row>
                    <el-button slot="reference" type="success" size="small">批量调拨</el-button>
                </el-popover>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.purchase.selfIn.create')">
                <el-button type="success" size="small" @click="jumpPurchaseSelfInCreate">批量单方采购 </el-button>
            </el-col>
        </el-row>
        <el-row style="margin-left: 10px">
            <el-col :span="6" v-if="hasPrivilege('menu.goods.price.create')">
                <el-button type="success" size="small" @click="batchChangePrice">批量调价</el-button>
            </el-col>
            <el-col :span="6" v-if="hasPrivilege('menu.goods.goods.edit')">
                <el-button type="success" size="small" @click="batchChangeCustomBar">批量编辑自编码</el-button>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" style="float: right" @click="showDialog = false">取消</el-button>
        </div>
    </ef-dialog>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import { Loading } from 'element-ui';
import EfDialog from 'components/EfDialog';
import SurplusGoods from 'js/SurplusGoods';

export default {
    name: 'Batcher',
    components: { EfDialog },
    data() {
        return {
            selectData: [],
            showDialog: false,
            batchDialog: {
                showBatchP: false,
                showBatchD: false,
                switchType: false,
                switchDept: false,
                repoCode: '',
                repoDepts: [],
                otherDepts: [],
                deptName: '',
                deptNameShow: '',
                deptGroupCode: '',
                otherDeptCode: '',
            },
            // 检验勾选商品数组
            selectedNewestData: [],
        };
    },
    async mounted() {
        this.batchDialog.repoDepts = await this.$efApi.deptApi.companyAllRepos();
    },
    watch: {
        async showDialog() {
            this.batchDialog.repoDepts = await this.$efApi.deptApi.companyAllRepos();
        },
    },
    methods: {
        show(selectData, selectedNewestData) {
            this.selectData = selectData;
            this.selectedNewestData = selectedNewestData;
            this.clearPopoverData();
            if (selectData.length == 0) {
                this.$message.error('您还没有选择商品,请选择同一机构的商品再进行批量操作');
                this.close();
                return;
            } else {
                const temSet = new Set(selectData.map((p) => p.deptCode));
                if (temSet.size != 1) {
                    this.$message.error('请选择同一个机构的商品再进行批量操作');
                    this.close();
                    return;
                }
            }
            this.batchDialog.deptName = selectData[0].deptName;
            this.batchDialog.deptNameShow = '选择的商品所属机构为【 ' + this.batchDialog.deptName + '】';

            this.batchDialog.deptGroupCode = selectData[0].groupCode;
            this.showDialog = true;
        },
        clearPopoverData() {
            this.batchDialog.repoCode = '';
            this.batchDialog.otherDeptCode = '';
            this.batchDialog.switchDept = false;
            this.batchDialog.switchType = false;
            this.batchDialog.deptName = '';
            this.batchDialog.deptNameShow = '';
        },
        close() {
            this.showDialog = false;
        },

        /*批量上下架*/
        batchUp(type) {
            this.close();
            const goodsCodes = new Array(this.selectData.map((p) => p.code));
            const _param = {
                goodsCodes: goodsCodes,
                deptCode: this.selectData[0].deptCode,
                type: type,
                message: '确定' + (type === 1 ? '下架' : '上架') + '已勾选商品',
            };
            UrlUtils.PostRemote(
                this,
                '/goods/goods/batchPutOrDownAway',
                _param,
                null,
                () => {
                    this.$message.success('操作成功');
                    this.$emit('onReload');
                },
                (data) => {
                    this.$emit('onReload');
                }
            );
        },
        /*批量调价*/
        batchChangePrice() {
            this.close();
            Util.nameJump(this, 'menu.goods.price.batchChangePrice', ['库存管理', '批量调价'], {
                goodsPageSelect: this.selectData,
                deptType: this.selectData[0].deptType,
                goodsPageDeptCode: this.selectData[0].deptCode,
            });
        },
        /*批量编辑自编码*/
        batchChangeCustomBar() {
            this.close();
            Util.nameJump(this, 'menu.goods.price.batchChangeCustomBar', ['库存管理', '批量编辑自编码'], {
                goodsPageSelect: this.selectData,
                deptType: this.selectData[0].deptType,
                goodsPageDeptCode: this.selectData[0].deptCode,
            });
        },
        /*批量报损*/
        jumpStockLossCreate() {
            this.close();
            Util.nameJump(this, 'menu.stock.loss.create', ['库存管理', '报损调整', '新建报损'], {
                goodsPageSelect: this.selectData,
                goodsPageDeptCode: this.selectData[0].deptCode,
            });
        },
        /*批量报溢*/
        jumpStockSurplusCreate() {
            // 商品报溢检验
            if (!SurplusGoods.checkSurplusGoods(this.selectedNewestData)) {
                this.$message.error('勾选项中含有未上架商品， 请先上架后在添加');
                return;
            }
            // 校验通过执行
            this.close();
            Util.nameJump(this, 'menu.stock.surplus.create', ['库存管理', '报溢调整', '新建报溢'], {
                goodsPageSelect: this.selectData,
                goodsPageDeptCode: this.selectData[0].deptCode,
            });
        },
        /*批量内购*/
        jumpStockReceiveCreate() {
            this.close();
            Util.nameJump(this, 'menu.stock.receive.create', ['库存管理', '内购管理', '新建内购'], {
                goodsPageSelect: this.selectData,
                goodsPageDeptCode: this.selectData[0].deptCode,
            });
        },
        /*批量采购*/
        jumpPurchaseOrderCreate() {
            if (this.batchDialog.repoCode) {
                const loading = Loading.service({
                    fullscreen: true,
                    lock: true,
                    text: '验证选择的商品中......',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                const temSet = new Array(this.selectData.map((p) => p.sku.code));
                const _param = { deptCode: this.batchDialog.repoCode, goodsStatusIn: [1], skuCodes: temSet };
                (async () => {
                    const rst = await this.$efApi.goodsApi.queryGoods(_param);
                    loading.close();
                    if (rst.count > 0) {
                        const haveDeleteGoods = rst.count < temSet[0].length;
                        if (haveDeleteGoods) {
                            this.$message.error('部分商品此配送中心无法采购,已删除未显示');
                        }
                        Util.nameJump(this, 'menu.purchase.order.create', ['采购管理', '采购订单', '新建订单'], {
                            goodsPageSelect: rst.data,
                            goodsPageDeptCode: this.selectData[0].deptCode,
                            goodsPageRepoCode: this.batchDialog.repoCode,
                        });
                        this.close();
                    } else {
                        this.$message.error('此配送中心没有已选择的商品');
                    }
                })();
            } else {
                this.$message.error('请选择从哪个配送中心采购');
            }
        },
        /*批量单方采购*/
        jumpPurchaseSelfInCreate() {
            const loading = Loading.service({
                fullscreen: true,
                lock: true,
                text: '验证选择的商品中......',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const temSet = new Array(this.selectData.map((p) => p.sku.code));
            const _param = {
                deptType: this.selectData[0].deptType,
                deptCode: this.selectData[0].deptCode,
                goodsStatusIn: [0, 1],
                skuSinglePurchaseFlag: 1,
                skuCodes: temSet,
            };
            (async () => {
                const rst = await this.$efApi.goodsApi.queryGoods(_param);
                loading.close();
                if (rst.count > 0) {
                    const haveDeleteGoods = rst.count < temSet[0].length;
                    if (haveDeleteGoods) {
                        this.$message.error('部分商品无法单方采购,已删除未显示');
                    }
                    Util.nameJump(this, 'menu.purchase.selfIn.create', ['采购管理', '单方采购入库', '新建采购'], {
                        goodsPageSelect: rst.data,
                        goodsPageDeptCode: this.selectData[0].deptCode,
                    });
                } else {
                    this.$message.error('已选择的商品没有单方采购的权限');
                }
            })();
        },
        /*批量调拨*/
        jumpAllocateCreate() {
            let queryDept = this.selectData[0].deptCode;
            if (this.batchDialog.switchType) {
                if (Util.isEmpty(this.batchDialog.otherDeptCode)) {
                    this.$message.error('内部调拨需要选择另一家调拨机构');
                    return;
                }
                if (!this.batchDialog.switchDept) {
                    queryDept = this.batchDialog.otherDeptCode;
                }
            }
            const loading = Loading.service({
                fullscreen: true,
                lock: true,
                text: '验证选择的商品中......',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const temSet = new Array(this.selectData.map((p) => p.sku.code));
            const _param = {
                deptCode: queryDept,
                goodsStatusIn: [0, 1],
                skuCodes: temSet,
            };
            (async () => {
                const rst = await this.$efApi.goodsApi.queryGoods(_param);
                loading.close();
                if (rst.count > 0) {
                    const haveDeleteGoods = rst.count < temSet[0].length;
                    if (haveDeleteGoods) {
                        this.$message.error('部分商品调出机构没有,已删除未显示');
                    }
                    Util.nameJump(this, 'menu.stock.allocate.create', ['库存管理', '调拨申请', '新建调拨'], {
                        goodsPageSelect: rst.data,
                        deptGroupCode: this.batchDialog.deptGroupCode,
                        goodsPageDeptCode: this.selectData[0].deptCode,
                        otherDeptCode: this.batchDialog.otherDeptCode,
                        allocateType: this.batchDialog.switchType,
                        deptAllocateType: this.batchDialog.switchDept,
                    });
                    this.close();
                } else {
                    this.$message.error('调出门店没有已选择的商品');
                }
            })();
        },
        showBatchAllocate() {
            this.queryNewDepts();
        },
        changeSwitchType() {
            this.batchDialog.switchDept = false;
        },
        queryNewDepts() {
            if (this.batchDialog.deptGroupCode) {
                (async () => {
                    const deptGroupRelatedDepts = await this.$efApi.deptApi.companyDeptGroupRelatedDepts(
                        this.batchDialog.deptGroupCode
                    );
                    this.batchDialog.otherDepts = deptGroupRelatedDepts.filter(
                        (e) => e.code != this.selectData[0].deptCode
                    );
                })();
            } else {
                this.batchDialog.otherDepts = [];
            }
        },
    },
};
</script>
